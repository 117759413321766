import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getTalkTimeCdr } from '../../api/dialer';

const formatDuration = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return {
        formatted: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
        hours,
        minutes,
        seconds
    };
};

const TalkTime = () => {
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [talkTimeData, setTalkTimeData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchTalkTimeData = async () => {
        setIsLoading(true);
        try {
            const response = await getTalkTimeCdr({
                startDate,
                endDate
            });
            const processedData = response.data.map(item => ({
                ...item,
                duration_formatted: formatDuration(item.total_duration).formatted,
                duration_hours: formatDuration(item.total_duration).hours
            }));
            setTalkTimeData(processedData);
        } catch (error) {
            console.error('Error fetching talk time data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const downloadCSV = () => {
        const csvContent = [
            // Headers
            ['Branch', 'Agent Name', 'TL Name', 'BM Name', 'Total Duration', 'Total Hours', 'Answered Calls', 'Missed Calls', 'Report Date', 'Sales Team', 'Role', 'Campaign'].join(','),
            // Data rows
            ...talkTimeData.map(row => [
                row.bm_branch,
                row.agent_name,
                row.tl_name,
                row.bm_name,
                row.duration_formatted,
                row.duration_hours,
                row.answered_calls,
                row.missed_calls,
                row.report_date,
                row.sales_team,
                row.role,
                row.campaign_name
            ].map(val => `"${val}"`).join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `talk_time_report_${new Date().toISOString().split('T')[0]}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        fetchTalkTimeData();
    }, []);

    return (
        <div>
            <div style={{ marginBottom: '20px' }}>
                <label>Start Date: </label>
                <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                />
                <label>End Date: </label>
                <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                />
                <button onClick={fetchTalkTimeData} disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Fetch Data'}
                </button>
                {talkTimeData.length > 0 && (
                    <button onClick={downloadCSV}>
                        Download CSV
                    </button>
                )}
            </div>

            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Branch</th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Agent Name</th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>TL Name</th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>BM Name</th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Total Duration (HH:MM:SS)</th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Total Hours</th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Answered Calls</th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Missed Calls</th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Report Date</th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Sales Team</th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Role</th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Campaign</th>
                    </tr>
                </thead>
                <tbody>
                    {talkTimeData.map((row, index) => (
                        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.bm_branch}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.agent_name}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.tl_name}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.bm_name}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.duration_formatted}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.duration_hours}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.answered_calls}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.missed_calls}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.report_date}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.sales_team}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.role}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.campaign_name}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {talkTimeData.length === 0 && !isLoading && (
                <div style={{ textAlign: 'center', padding: '20px', color: 'gray' }}>
                    No data available. Please select a date range and fetch data.
                </div>
            )}
        </div>
    );
};

export default TalkTime;