import client from "./client";

export const addReport = async (obj, reportName) => {
  try {
    const { data } = await client.post(`/report/addReport/${reportName}`, {
      obj,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const incomingDisporeport = async () => {
  try {
    const { data } = await client.get(`/report/incomingDispo`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const agentWiseDisposition = async (obj, startDate, endDate) => {
  try {
    const { data } = await client.post(
      `/report/agentWiseDisposition/${startDate}/${endDate}`,
      obj
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
