import React, { useState, useEffect } from "react";
import { getSearchNumber } from "../../api/searchNumber";
import { notification, Button, Input, Table, Spin, Empty } from "antd";
import moment from "moment";

const SearchNumber = () => {
  const [time, setTime] = useState("");
  const [agentName, setAgentName] = useState("");
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  // Separate search function
  const handleSearch = async () => {
    if (!time || !agentName || !number) {
      notification.warning({
        message: "Missing Fields",
        description: "Please fill in all required fields.",
      });
      return;
    }

    setLoading(true);
    try {
      const results = await getSearchNumber(time, agentName, number);

      if (Array.isArray(results.data)) {
        setSearchResults(results.data); // This will update the state with the results
      } else {
        setSearchResults([]); // No results found, reset the searchResults
        notification.info({
          message: "No Results",
          description: "No matching records found.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description:
          error.message || "Something went wrong while fetching data.",
      });
      setSearchResults([]); // Reset the results in case of error
    } finally {
      setLoading(false); // Turn off the loading spinner after the operation
    }
  };

  // Clear search fields
  const handleClear = () => {
    setTime("");
    setAgentName("");
    setNumber("");
    setSearchResults([]);
  };

  const columns = [
    {
      title: "Agent Name",
      dataIndex: "agent_name",
      key: "agent_name",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },
  ];
  return (
    <div className="container mx-auto p-4">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4 text-center">
          Search Number
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Agent Name
            </label>
            <Input
              placeholder="Enter Agent Name"
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Time
            </label>
            <Input
              placeholder="Enter Time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Last 2 digits of number
            </label>
            <Input
              placeholder="Enter last 2 digits"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              maxLength={2}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>
        </div>

        <div className="mt-6 flex gap-4">
          <Button
            type="primary"
            onClick={handleSearch}
            className="flex-1 bg-blue-600 text-white hover:bg-blue-700"
          >
            Search
          </Button>
          <Button
            onClick={handleClear}
            className="flex-1 border-gray-300 hover:bg-gray-50"
          >
            Clear
          </Button>
        </div>

        <div className="mt-6">
          {loading ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <Spin size="large" />
            </div>
          ) : searchResults.length > 0 ? (
            <div>
              {searchResults.map((result, index) => (
                <div key={index} className="mb-4 p-4 border rounded-md">
                  <p>
                    <strong>Agent Name:</strong> {result.Agent_Name}
                  </p>
                  <p>
                    <strong>Time:</strong> {result.Time}
                  </p>
                  <p>
                    <strong>Date:</strong>{" "}
                    {moment(result.Date).format("YYYY-MM-DD")}
                  </p>
                  <p>
                    <strong>Client Number:</strong> {result.Client_Number}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <Empty description="No results found" className="my-8" />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchNumber;
