import React, { useState } from "react";
import { Button, Select } from "antd";
import toast from "react-hot-toast";
import { IvrServiceForm } from "../../api/ivr";

const { Option } = Select;

const IvrForm = ({ setIsModalVisible }) => {
  // Sample arrays for options
  const accounts = ["OBD1", "OBD2", "OBD3"];
  const sources = ["GT", "PT", "ICT", "UDY", "NETWORK"];
  const services = ["MSME LOAN", "SEED FUND", "MSME HACKTHON", "INCOME TAX"];

  const [account, setAccount] = useState("");
  const [source, setSource] = useState("");
  const [service, setService] = useState("");

  const handleSave = async () => {
    if (!account) {
      toast.error("Please select Account");
      return;
    }
    if (!source) {
      toast.error("Please select Source");
      return;
    }
    if (!service) {
      toast.error("Please select Service");
      return;
    }
    try {
      const data = await IvrServiceForm({
        account: account,
        source: source,
        service: service,
      });
      if (data.success) {
        toast.success(data.msg);
        setAccount("");
        setService("");
        setSource("");
      }
    } catch (error) {
      console.error("Form insert error", error);
    }
    setIsModalVisible(false);
    // Make API call with selected values
    // Your API call logic goes here
  };

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <label htmlFor="account" className="font-semibold">
          Account :{" "}
        </label>
        <Select
          id="account"
          placeholder="Select Account"
          style={{ width: 200 }}
          onChange={(value) => setAccount(value)}
          value={account}
        >
          {accounts.map((item, index) => (
            <Option key={index} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </div>

      <div style={{ marginBottom: 16 }}>
        <label htmlFor="source" className="font-semibold">
          Source :{" "}
        </label>
        <Select
          id="source"
          placeholder="Select Source"
          style={{ width: 200 }}
          onChange={(value) => setSource(value)}
          value={source}
        >
          {sources.map((item, index) => (
            <Option key={index} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </div>

      <div style={{ marginBottom: 16 }}>
        <label htmlFor="service" className="font-semibold">
          Service :{" "}
        </label>
        <Select
          id="service"
          placeholder="Select Service"
          style={{ width: 200 }}
          onChange={(value) => setService(value)}
          value={service}
        >
          {services.map((item, index) => (
            <Option key={index} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </div>

      <Button type="primary" onClick={handleSave}>
        Save
      </Button>
    </div>
  );
};

export default IvrForm;
