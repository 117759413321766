import React, { useState } from "react";
import * as XLSX from "xlsx";
import { Upload, Button, DatePicker, message } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import LeadsTable from "./MenuTable/LeadsTable";
import GroupedInsights from "./MenuTable/GroupedInsights";
import moment from "moment-timezone";
import { uploadMenuLeads } from "../../api/menuLeads";
import toast from "react-hot-toast";
import Papa from "papaparse";

function MenuUtils() {
  const [leads, setLeads] = useState([]);
  const [groupedBySource, setGroupedBySource] = useState([]);
  const [groupedByData, setGroupedByData] = useState([]);
  const [groupedBySlab, setGroupedBySlab] = useState([]);
  const [groupedByBranch, setGroupedByBranch] = useState([]);
  const [menuDate, setMenuDate] = useState(null);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for the button

  // for csv
  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      Papa.parse(e.target.result, {
        header: true,
        dynamicTyping: true,
        complete: (results) => {
          let json = results.data;
          json = json.filter((lead) => lead.ContactNumber);
          setLeads(json);
          setGroupedBySource(formatGroupedData(groupBy(json, "Source")));
          setGroupedByData(formatGroupedData(groupBy(json, "CompanySource")));
          setGroupedBySlab(formatGroupedData(groupBy(json, "Slab")));
          setGroupedByBranch(formatGroupedData(groupBy(json, "Branch")));
          setFileName(file.name);
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        },
      });
    };
    reader.readAsText(file);
  };

  // for xlsx
  // const handleFileUpload = (file) => {
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     const data = new Uint8Array(e.target.result);
  //     const workbook = XLSX.read(data, { type: "array" });
  //     const sheetName = workbook.SheetNames[2]; // Ensure this is the correct sheet name or index
  //     const sheet = workbook.Sheets[sheetName];
  //     const json = XLSX.utils.sheet_to_json(sheet);
  //     console.log(json);
  //     setLeads(json);
  //     setGroupedBySource(formatGroupedData(groupBy(json, "Source")));
  //     setGroupedByData(formatGroupedData(groupBy(json, "Data")));
  //     setGroupedBySlab(formatGroupedData(groupBy(json, "Slab")));
  //     setGroupedByBranch(formatGroupedData(groupBy(json, "Branch")));
  //     setFileName(file.name);
  //   };
  //   reader.readAsArrayBuffer(file);
  // };

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const formatGroupedData = (groupedData) => {
    return Object.entries(groupedData).map(([key, value]) => ({
      key,
      count: value.length,
    }));
  };

  const handleUploadMenu = async () => {
    if (menuDate) {
      const selectedDate = menuDate.format("YYYY-MM-DD");
      setLoading(true); // Set loading to true when uploading starts
      try {
        const data = await uploadMenuLeads({
          menuLeads: leads,
          menuDate: selectedDate,
        });
        if (data.success) {
          toast.success(data.message);
        } else {
          toast.error(`Failed to upload menu leads: ${data.error}`);
        }
      } catch (error) {
        console.error("Error uploading menu leads:", error);
        toast.error("An error occurred while uploading menu leads.");
      } finally {
        setLoading(false); // Set loading to false when upload completes
      }
    } else {
      toast.error("Menu Data not selected !");
    }
  };

  const handleRemoveFile = () => {
    setFileName("");
    setLeads([]);
    setGroupedBySource([]);
    setGroupedByData([]);
    setGroupedBySlab([]);
    setGroupedByBranch([]);
  };

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-3xl mb-2 text-center">Lead Report</h1>
      <hr className="mb-3 border border-gray-400 border-dashed" dashed />
      <Upload
        accept=".csv"
        showUploadList={false}
        beforeUpload={(file) => {
          handleFileUpload(file);
          return false; // Prevent upload
        }}
      >
        <Button icon={<UploadOutlined />} className="mb-4">
          Click to Upload
        </Button>
      </Upload>

      <DatePicker onChange={(date) => setMenuDate(date)} className="ml-4" />

      <Button
        type="primary"
        className="ml-4"
        onClick={handleUploadMenu}
        loading={loading} // Bind loading state to button
      >
        {loading ? "Uploading..." : "Upload Menu Leads"}
      </Button>

      {fileName && (
        <p className="">
          <span className="mr-2 border border-gray-400 p-2 rounded-md">
            Uploaded file: {fileName}
          </span>
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={handleRemoveFile}
          />
          <span className="ml-2 font-semibold">
            {leads.length} Records .....
          </span>
        </p>
      )}

      <div className="grid grid-cols-3 gap-4">
        <GroupedInsights title="Lead Source Wise" data={groupedBySource} />
        <GroupedInsights title="Company Source Wise" data={groupedByData} />
        <GroupedInsights title="Slab Wise" data={groupedBySlab} />
        <GroupedInsights title="Branch Wise" data={groupedByBranch} />
      </div>

      <LeadsTable leads={leads} />
    </div>
  );
}

export default MenuUtils;
