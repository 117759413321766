import React, { useState } from "react";
import { Table, DatePicker, Button } from "antd";
import moment from "moment";
import { getFilteredMenuLeads } from "../../api/menuLeads";
import toast from "react-hot-toast";
const { RangePicker } = DatePicker;

const AllMenuLeads = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);

  const fetchLeads = async () => {
    setLoading(true);
    const startDate = dateRange ? dateRange[0].format("YYYY-MM-DD") : null;
    const endDate = dateRange ? dateRange[1].format("YYYY-MM-DD") : null;

    try {
      const data = await getFilteredMenuLeads({
        startDate,
        endDate,
        dateFiltering: true,
      });
      if (data.success) {
        setLeads(data.data);
      } else {
        toast.error("Failed to fetch data");
      }
    } catch (error) {
      toast.error("An error occurred while fetching data");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchAllLeads = async () => {
    setLoading(true);

    try {
      const data = await getFilteredMenuLeads({
        startDate: null,
        endDate: null,
        dateFiltering: false,
      });
      if (data.success) {
        setLeads(data.data);
      } else {
        toast.error("Failed to fetch data");
      }
    } catch (error) {
      toast.error("An error occurred while fetching data");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Lead ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Contact",
      dataIndex: "CONTACT",
      key: "CONTACT",
    },
    {
      title: "Company Name",
      dataIndex: "COMPANY_NAME",
      key: "COMPANY_NAME",
    },
    {
      title: "Company Email",
      dataIndex: "COMPANY_EMAIL",
      key: "COMPANY_EMAIL",
    },
    {
      title: "State",
      dataIndex: "STATE",
      key: "STATE",
    },
    {
      title: "Slab",
      dataIndex: "SLAB",
      key: "SLAB",
    },
    {
      title: "Company Source",
      dataIndex: "COMPANY_SOURCE",
      key: "COMPANY_SOURCE",
    },
    {
      title: "Lead Source",
      dataIndex: "LEAD_SOURCE",
      key: "LEAD_SOURCE",
    },
    {
      title: "Branch",
      dataIndex: "BRANCH",
      key: "BRANCH",
    },
    {
      title: "Service",
      dataIndex: "SERVICE",
      key: "SERVICE",
    },
    {
      title: "Menu Date",
      dataIndex: "MENU_DATE",
      key: "MENU_DATE",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Generation Date",
      dataIndex: "GENERATION_DATE",
      key: "GENERATION_DATE",
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Created At",
      dataIndex: "CREATEDAT",
      key: "CREATEDAT",
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Other",
      dataIndex: "OTHER",
      key: "OTHER",
      render: (other) => JSON.stringify(other),
    },
    {
      title: "To",
      dataIndex: "TO",
      key: "TO",
      render: (other) => JSON.stringify(other),
    },
  ];

  return (
    <div className="w-full">
      <div className="flex justify-between items-center w-full">
        <div>
          <RangePicker
            className="ml-4"
            value={dateRange}
            onChange={(dates) => setDateRange(dates)}
          />
          <Button
            type="primary"
            className="ml-4"
            onClick={fetchLeads}
            loading={loading}
          >
            Fetch Leads
          </Button>
          <Button
            type="primary"
            className="ml-4"
            onClick={fetchAllLeads}
            loading={loading}
          >
            Fetch All Leads
          </Button>
        </div>
        <span className="font-semibold text-xl mr-4">
          {leads.length} Records ....
        </span>
      </div>
      <br />
      <br />
      <Table
        columns={columns}
        dataSource={leads}
        loading={loading}
        rowKey="id"
      />
    </div>
  );
};

export default AllMenuLeads;
