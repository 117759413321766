import React, { useState } from "react";
import {
  Form,
  Select,
  DatePicker,
  Button,
  Card,
  Space,
  Alert,
  Typography,
} from "antd";
import {
  SearchOutlined,
  ReloadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { saveAs } from "file-saver";
import { getIvrData } from "../../api/ivr";

const { Title } = Typography;
const { Option } = Select;

const IVR_DATA = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);

  const categories = ["PT", "GT"];
  const subCategories = ["ADI", "CHE"];

  const handleReset = () => {
    form.resetFields();
    setFilteredData([]);
    setError(null);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setError(null);

    try {
      const formattedValues = {
        ...values,
        ivrStartDate: values.ivrStartDate?.format("YYYY-MM-DD"),
        dtmfStartDate: values.dtmfStartDate?.format("YYYY-MM-DD"),
        ansCallStartDate: values.ansCallStartDate?.format("YYYY-MM-DD"),
        apiReplyStartDate: values.apiReplyStartDate?.format("YYYY-MM-DD"),
      };
      const data = await getIvrData(formattedValues);
      setFilteredData(data.data || []);
    } catch (err) {
      setError("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const handleDownloadCSV = () => {
    // Create the CSV header
    const csvHeader = ["Mobile"];

    // Create CSV rows by mapping over filteredData
    const csvRows = filteredData.map((contact) => [contact]); // Each contact is now wrapped in an array

    // Combine header and rows into a single array
    const csvContent = [csvHeader, ...csvRows]
      .map((row) => row.join(",")) // Join each row's values with commas
      .join("\n"); // Join rows with new line

    // Create a blob and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "filtered_data.csv"); // Use file-saver to save the CSV
  };

  return (
    <div className="p-6 max-w-6xl mx-auto">
      <Card
        className="shadow-lg"
        title={
          <Title level={3} className="mb-0">
            IVR Data Filter Dashboard
          </Title>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="mb-6"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <Form.Item label="Source" name="source">
              <Select placeholder="Select source" allowClear>
                {categories.map((cat) => (
                  <Option key={cat} value={cat}>
                    {cat}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Branch" name="branch">
              <Select placeholder="Select branch" allowClear>
                {subCategories.map((subCat) => (
                  <Option key={subCat} value={subCat}>
                    {subCat}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="IVR Start Date" name="ivrStartDate">
              <DatePicker className="w-full" />
            </Form.Item>

            <Form.Item label="DTMF Start Date" name="dtmfStartDate">
              <DatePicker className="w-full" />
            </Form.Item>

            <Form.Item label="Answer Call Start Date" name="ansCallStartDate">
              <DatePicker className="w-full" />
            </Form.Item>

            <Form.Item label="API Reply Start Date" name="apiReplyStartDate">
              <DatePicker className="w-full" />
            </Form.Item>
          </div>

          <div className="flex justify-end space-x-4">
            <Button onClick={handleReset} icon={<ReloadOutlined />}>
              Reset
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </Form>

        {error && (
          <Alert
            message="Error"
            description={error}
            type="error"
            showIcon
            className="mb-6"
          />
        )}

        {filteredData.length > 0 && (
          <div>
            <Space className="mb-4">
              <Button
                type="default"
                icon={<DownloadOutlined />}
                onClick={handleDownloadCSV}
              >
                Download CSV
              </Button>
            </Space>
          </div>
        )}
      </Card>
    </div>
  );
};

export default IVR_DATA;
