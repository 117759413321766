import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  notification,
  Tabs,
  Card,
  Row,
  Col,
  Modal,
  Table,
  Upload,
  Spin,
  Tooltip,
} from "antd";
import {
  addBulkRefund,
  addRefund,
  deleteRefund,
  getAllRefund,
  getBdms,
  updateRefund,
} from "../../api/refund";
import moment from "moment";
import { FaFileExport } from "react-icons/fa";
import {
  CheckOutlined,
  DownloadOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Papa from "papaparse";
const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const RefundList = ({
  searchText,
  filteredRefundData,
  handleSearch,
  handleEdit,
  handleDelete,
  setFilteredRefundData,
  refundData,
}) => (
  <div>
    <div className="mb-6">
      <Input
        placeholder="Search by Booking ID And Name"
        prefix={<SearchOutlined className="text-gray-400" />}
        onChange={handleSearch}
        className="max-w-md"
        allowClear
        value={searchText}
      />
    </div>

    <div className="mb-6 flex justify-between items-center">
      <DatePicker.RangePicker
        format="YYYY-MM-DD"
        onChange={(dates) => {
          if (dates) {
            const [start, end] = dates;

            const filteredData = refundData.filter((refund) => {
              const refundDate = new Date(refund.dateOfRefund);
              const startDate = new Date(start.format("YYYY-MM-DD"));
              const endDate = new Date(end.format("YYYY-MM-DD"));

              const isInRange =
                refundDate >= startDate && refundDate <= endDate;
              return isInRange;
            });

            setFilteredRefundData(filteredData);
          } else {
            setFilteredRefundData(refundData);
          }
        }}
      />
    </div>

    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {filteredRefundData.length === 0 ? (
        <div className="col-span-2 text-center py-8 text-gray-500">
          {searchText
            ? "No refunds found matching your search"
            : "No refunds available"}
        </div>
      ) : (
        filteredRefundData?.map((refund) => (
          <Card
            key={refund.id}
            className="shadow-sm hover:shadow-md transition-shadow"
            title={refund?.partyName}
            extra={
              <>
                <Button
                  type="primary"
                  onClick={() => handleEdit(refund)}
                  className="text-orange-500 border-orange-500 hover:bg-orange-100"
                >
                  Edit
                </Button>

                <Button
                  // type="primary"
                  onClick={() => handleDelete(refund.id)}
                  className="text-white border-red-700 hover:bg-red-100 ml-5 bg-red-500"
                >
                  Delete
                </Button>
              </>
            }
          >
            <p>
              <strong>Booking ID:</strong> {refund?.bookingID}
            </p>
            <p>
              <strong>Amount:</strong> {refund?.amountOfRefund}
            </p>
            <p>
              <strong>Date:</strong>{" "}
              {moment(refund?.dateOfRefund).format("YYYY-MM-DD")}
            </p>
            <p>
              <strong>Service:</strong> {refund?.service}
            </p>
            <p>
              <strong>Branch:</strong> {refund?.branch}
            </p>
          </Card>
        ))
      )}
    </div>
  </div>
);

const RefundForm = () => {
  const [form] = Form.useForm();
  const [selectedEspFinance, setSelectedEspFinance] = useState(null);
  const [bdmOptions, setBdmOptions] = useState([]);
  const [refundData, setRefundData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [searchText, setSearchText] = useState("");
  const [filteredRefundData, setFilteredRefundData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [pastRecord, setPastRecord] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const userId = localStorage.getItem("id");
  const [csvFile, setCsvFile] = useState(null);
  const [loading, setLoading] = useState(false);

  // Handle form submission
  const handleSubmit = async (values) => {
    const formattedValues = {
      ...values,
      dateOfRefund: values.dateOfRefund
        ? values.dateOfRefund.format("YYYY-MM-DD")
        : null,
    };
    let response
    try {
      if (isEditing) {
        response = await updateRefund(
          selectedId,
          formattedValues,
          pastRecord,
          userId
        );

        notification.success({
          message: "Refund Request Updated Successfully",
          description: "Your refund request has been updated successfully.",
        });
      } else {
        response = await addRefund(formattedValues);
        notification.success({
          message: "Refund Request Submitted Successfully",
          description: "Your refund request has been submitted successfully.",
        });
      }

      form.resetFields();
      setPastRecord(null);
      setIsEditing(false);
      getRefundData();
      setActiveTab("2");
      setSelectedId(null);
    } catch (error) {
      console.error("Error submitting refund:", error);
      notification.error({
        message: `Error ${isEditing ? "Updating" : "Submitting"
          } Refund Request`,
        description: `There was an error while ${isEditing ? "updating" : "submitting"
          } your refund request.`,
      });
    }
  };

  const handleEspFinanceChange = (value) => {
    setSelectedEspFinance(value);
    // Reset the 'otherEspFinance' field when a different option is selected
    form.setFieldsValue({ otherEspFinance: undefined });
  };
  const handleEdit = (record) => {
    setPastRecord(record);
    setSelectedId(record.id);
    setIsEditing(true);
    setActiveTab("1");
    form.setFieldsValue({
      ...record,
      dateOfRefund: record.dateOfRefund ? moment(record.dateOfRefund) : null,
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this refund?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => handleDelete(id),
    });
  };

  useEffect(() => {
    getBdmNames();
    getRefundData();
  }, []);

  const getBdmNames = async () => {
    try {
      const response = await getBdms();
      setBdmOptions(response?.data || []);
    } catch (error) {
      console.error("Error fetching BDM names:", error);
    }
  };

  const getRefundData = async () => {
    try {
      const response = await getAllRefund();
      setRefundData(response?.data || []);
    } catch (error) {
      console.error("Error fetching All Refund Data", error);
    }
  };
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);

    const filtered = value.trim()
      ? refundData.filter(
        (item) =>
          item?.bookingID?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item?.partyName?.toLowerCase()?.includes(value?.toLowerCase())
      )
      : refundData;

    setFilteredRefundData(filtered);
  };

  useEffect(() => {
    setFilteredRefundData(refundData);
  }, [refundData]);

  const handleDelete = async (id) => {
    const response = await deleteRefund(id, userId);
    if (response) {
      getRefundData();
      notification.success({
        message: "Refund Data Deleted Successfully",
        description: "Your refund data has been deleted successfully.",
      });
    }
  };

  const handleCSVUpload = (file) => {
    const reader = new FileReader();
    setShowButton(true);

    if (showButton) {
      setLoading(true);
      reader.onload = async (event) => {
        const csvData = event.target.result;

        // Parse CSV Data
        Papa.parse(csvData, {
          header: true,
          skipEmptyLines: true,
          complete: async function (result) {
            const { data } = result;

            try {
              // Ensure data is in an array format even if there's only one row
              const rows = Array.isArray(data) ? data : [data];

              for (const row of rows) {
                const formattedRow = {
                  actualRefund: row["Actul Refund"]?.trim(), // Safely access and trim fields
                  amountOfRefund: row[" Total Amount Of Refund "]?.trim(),
                  bdm1: row["BDM1"]?.trim(),
                  bdm2: row["BDM2"]?.trim(),
                  bookingID: row["BOOKING ID"]?.trim(),
                  branch: row["BRANCH"]?.trim(),
                  dateOfRefund: row["Date of Refund"]?.trim(),
                  esp_finance: row["ESPL/Finance"]?.trim(),
                  partyName: row["Name of Party"]?.trim(),
                  reasonForRefund: row["Reason for refund"]?.trim(),
                  service: row["SERVICE"]?.trim(),
                  serviceDetails: row["SERVICE DETAILS"]?.trim(),
                  taxableAmount: row[" Taxble Amount of Refund "]?.trim(),
                };

                // Validate the formattedRow to ensure all required fields are present
                if (
                  !formattedRow.actualRefund ||
                  !formattedRow.amountOfRefund ||
                  !formattedRow.bookingID
                ) {
                  console.error("Invalid data:", formattedRow);
                  notification.error({
                    message: "Invalid Data",
                    description: `Missing required fields for Booking ID: ${formattedRow.bookingID || "Unknown"
                      }`,
                  });
                  continue;
                }

                // Call the API to add refund
                const response = await addBulkRefund(formattedRow);
                if (response) {
                  setLoading(false);
                  setCsvFile(null);
                  setShowButton(false);
                }
                // if (response) {
                //   notification.success({
                //     message: "Refund Request Added",
                //     description: `Successfully added refund for Booking ID: ${formattedRow.bookingID}`,
                //   });
                // } else {
                //   notification.error({
                //     message: "Refund Request Failed",
                //     description: `Failed to add refund for Booking ID: ${formattedRow.bookingID}`,
                //   });
                // }
              }
            } catch (error) {
              console.error("Error uploading CSV:", error);
              notification.error({
                message: "Error",
                description: "Failed to upload the CSV file. Please try again.",
              });
            }
          },
        });
      };
    }

    reader.readAsText(file);
  };

  const RefundFormContent = () => (
    <>
      <div className="flex justify-end mb-4">
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => {
            const link = document.createElement("a");
            link.href = "/sample_acc_csv.csv";
            link.download = "sample_acc_csv.csv";
            link.click();
          }}
        // className="bg-blue-500 hover:bg-blue-600 text-white"
        >
          Sample CSV
        </Button>

        <Upload
          accept=".csv"
          beforeUpload={(file) => {
            setCsvFile(file);
            handleCSVUpload(file);
            return false;
          }}
        >
          <Button
            type="primary"
            icon={<UploadOutlined />}
            className="bg-blue-500 hover:bg-blue-600 text-white ml-6"
          >
            Upload CSV
          </Button>
        </Upload>
        {showButton && (
          <Button
            disabled={loading}
            type="primary"
            icon={<CheckOutlined />}
            onClick={() => {
              handleCSVUpload(csvFile);
            }}
            className="bg-blue-500 hover:bg-blue-600 text-white ml-6"
          >
            {loading ? <Spin /> : "Submit"}
          </Button>
        )}
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="space-y-4 mt-10"
      >
        <Row gutter={16}>
          {/* Party Name */}
          <Col span={12}>
            <Form.Item
              name="partyName"
              label="Party Name"
              rules={[{ required: true, message: "Please enter Party Name" }]}
            >
              <Input placeholder="Enter Party Name" />
            </Form.Item>
          </Col>

          {/* BDM 1 */}
          <Col span={12}>
            <Form.Item
              name="bdm1"
              label="BDM 1"
              rules={[{ required: true, message: "Please Select BDM 1" }]}
            >
              <Select
                showSearch
                placeholder="Select BDM 1"
                optionFilterProp="children"
                onChange={(value) => {
                  // console.log("BDM 1 Selected Value:", value);
                }}
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }
              >
                {bdmOptions.map((bdm) => (
                  <Option key={bdm.id} value={bdm.name_crm}>
                    {bdm.name_crm}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/* BDM 2 */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="bdm2"
              label="BDM 2"
              rules={[{ required: true, message: "Please Select BDM 1" }]}
            >
              <Select
                showSearch
                placeholder="Select BDM 2"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }
              >
                {bdmOptions.map((bdm) => (
                  <Option key={bdm.id} value={bdm.name_crm}>
                    {bdm.name_crm}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* Branch */}
          <Col span={12}>
            <Form.Item
              name="branch"
              label="Branch"
              rules={[{ required: true, message: "Please select Branch" }]}
            >
              <Select placeholder="Select Branch">
                <Option value="AHMEDABAD">AHMEDABAD</Option>
                <Option value="DELHI">DELHI</Option>
                <Option value="BARODA">BARODA</Option>
                <Option value="CHENNAI">CHENNAI</Option>
                <Option value="LIGMR">LIGMR</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="bookingID"
              label="Booking ID"
              rules={[{ required: true, message: "Please enter Booking ID" }]}
            >
              <Input placeholder="Enter Booking ID" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="amountOfRefund"
              label="Amount of Refund"
              rules={[
                { required: true, message: "Please enter Amount of Refund" },
              ]}
            >
              <Input type="number" placeholder="Enter Refund Amount" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="actualRefund"
              label="Actual Refund"
              rules={[
                { required: true, message: "Please enter Actual Refund" },
              ]}
            >
              <Select placeholder="Select Actual Refund">
                <Option value="REFUND">REFUND</Option>
                <Option value="CA COMMISSION">CA COMMISSION</Option>
                <Option value="NAIF FILE">NAIF FILE</Option>
                <Option value="TDS REFUND">TDS REFUND</Option>
                <Option value="REFUND - REVERSAL">REFUND - REVERSAL</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="serviceDetails"
              label="Service Details"
              rules={[
                { required: true, message: "Please Enter Service Details" },
              ]}
            >
              <TextArea rows={3} placeholder="Enter Service Details" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="service"
              label="Service"
              rules={[{ required: true, message: "Please Enter Service" }]}
            >
              <Input placeholder="Enter Service" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="reasonForRefund"
              label="Reason for Refund"
              rules={[
                { required: true, message: "Please enter Reason for Refund" },
              ]}
            >
              <TextArea rows={3} placeholder="Enter Reason for Refund" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="esp_finance"
              label="ESP Finance"
              rules={[{ required: true, message: "Please select ESP" }]}
            >
              <Select
                placeholder="Select ESP Finance"
                onChange={handleEspFinanceChange}
              >
                <Option value="ESPL">ESPL</Option>
                <Option value="FINANCE">FINANCE</Option>
                <Option value="OTHER">OTHER</Option>
              </Select>
            </Form.Item>
            {selectedEspFinance === "OTHER" && (
              <Form.Item
                name="otherEspFinance"
                rules={[
                  { required: true, message: "Please enter Other ESP Finance" },
                ]}
              >
                <Input placeholder="Enter Other ESP Finance" />
              </Form.Item>
            )}
          </Col>

          <Col span={12}>
            <Form.Item
              name="dateOfRefund"
              label="Date of Refund"
              rules={[
                { required: true, message: "Please select Date of Refund" },
              ]}
            >
              <DatePicker format="YYYY-MM-DD" className="w-full" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="w-full text-orange-500 border-orange-500 hover:bg-orange-100"
          >
            {isEditing ? "Update Refund Request" : "Submit Refund Request"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );

  const handleTableChange = (pagination, filters, sorter) => {
    // console.log('Table params:', { pagination, filters, sorter });
  };

  const columns = [
    {
      title: "Party Name",
      dataIndex: "partyName",
      key: "partyName",
      // sorter: (a, b) => a.partyName.localeCompare(b.partyName),
    },
    {
      title: "Booking ID",
      dataIndex: "bookingID",
      key: "bookingID",
      // sorter: (a, b) => a.bookingID.localeCompare(b.bookingID),
    },
    {
      title: "Amount of Refund",
      dataIndex: "amountOfRefund",
      key: "amountOfRefund",
      sorter: (a, b) => a.amountOfRefund - b.amountOfRefund,
      render: (value) => `₹${value?.toLocaleString() || 0}`,
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      // sorter: (a, b) => a.branch.localeCompare(b.branch),
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      // sorter: (a, b) => a.service.localeCompare(b.service),
    },
    {
      title: "Date of Refund",
      dataIndex: "dateOfRefund",
      key: "dateOfRefund",
      sorter: (a, b) =>
        moment(a.dateOfRefund).unix() - moment(b.dateOfRefund).unix(),
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "BDM 1",
      dataIndex: "bdm1",
      key: "bdm1",
      sorter: (a, b) => a.bdm1.localeCompare(b.bdm1),
    },
    {
      title: "BDM 2",
      dataIndex: "bdm2",
      key: "bdm2",
      sorter: (a, b) => (a.bdm2 || "").localeCompare(b.bdm2 || ""),
    },
    {
      title: "Actual Refund",
      dataIndex: "actualRefund",
      key: "actualRefund",
    },
    {
      title: "ESP/Finance",
      dataIndex: "esp_finance",
      key: "esp_finance",
    },
    {
      title: "Reason for Refund",
      dataIndex: "reasonForRefund",
      key: "reasonForRefund",
      render: (text) => (
        <Tooltip title={text}>
          {text.length > 60 ? `${text.slice(0, 60)}...` : text}
        </Tooltip>
      ),
    },
    {
      title: "Service Details",
      dataIndex: "serviceDetails",
      key: "serviceDetails",
    },
  ];

  const downloadCSV = () => { };

  const importCSV = () => { };

  const exportToCSV = () => {
    if (filteredRefundData.length === 0) {
      console.log("No CSV data available to export");
      return;
    }
    const columnKeys = [
      "partyName",
      "bookingID",
      "amountOfRefund",
      "branch",
      "service",
      "dateOfRefund",
      "bdm1",
      "bdm2",
      "actualRefund",
      "esp_finance",
      "reasonForRefund",
      "serviceDetails",
    ];
    const headers = columnKeys.join(",");
    const csvContent = [
      headers,
      ...filteredRefundData.map((item) =>
        columnKeys
          .map((key) => {
            const value =
              item[key] === null || item[key] === undefined
                ? ""
                : String(item[key]);

            return `"${value
              .replace(/"/g, '""')
              .replace(/,/g, ";")
              .replace(/\n/g, " ")}"`;
          })
          .join(",")
      ),
    ].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "refund_full_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  return (
    <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <Tabs activeKey={activeTab} onChange={setActiveTab} className="mb-6">
        <TabPane tab={isEditing ? "Edit Refund" : "Add Refund"} key="1">
          <RefundFormContent />
        </TabPane>
        <TabPane tab="Edit Refunds" key="2">
          <RefundList
            searchText={searchText}
            filteredRefundData={filteredRefundData}
            handleSearch={handleSearch}
            handleEdit={handleEdit}
            handleDelete={confirmDelete}
            setFilteredRefundData={setFilteredRefundData}
            refundData={refundData}
          />
        </TabPane>
        <TabPane tab="All Refunds" key="3">
          <div className="mb-6 flex justify-between items-center">
            <DatePicker.RangePicker
              format="YYYY-MM-DD"
              onChange={(dates) => {
                if (dates) {
                  const [start, end] = dates;
                  const filteredData = refundData.filter((refund) => {
                    const refundDate = new Date(refund.dateOfRefund);
                    const startDate = new Date(start.format("YYYY-MM-DD"));
                    const endDate = new Date(end.format("YYYY-MM-DD"));

                    const isInRange =
                      refundDate >= startDate && refundDate <= endDate;
                    return isInRange;
                  });

                  setFilteredRefundData(filteredData);
                } else {
                  setFilteredRefundData(refundData);
                }
              }}
            />

            <div className="flex justify-end mb-4">
              <Button
                type="primary"
                icon={<FaFileExport />}
                onClick={exportToCSV}
                className="bg-blue-500 hover:bg-blue-600 text-white"
              >
                Export to CSV
              </Button>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={filteredRefundData}
            rowKey="id"
            onChange={handleTableChange}
            scroll={{ x: "max-content" }}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} refunds`,
            }}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default RefundForm;
