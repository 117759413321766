import { notification } from "antd";
import client from "./client";

export const addRefund = async (obj) => {
  try {
    const { data } = await client.post(`/refund/createRefund`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const addBulkRefund = async (data) => {
  try {
    // Ensure data is an array, even if there's only one item
    const payload = Array.isArray(data) ? data : [data];

    const response = await client.post("/refund/createRefundBulk", {
      refunds: payload,
    });

    if (response.data.success) {
      notification.success({
        message: "Bulk Upload Successful",
        description: "All refunds have been uploaded successfully.",
      });
      return response.data.success;
    } else {
      notification.error({
        message: "Bulk Upload Failed",
        description: response.data.error || "An error occurred.",
      });
    }
  } catch (error) {
    console.error("Bulk Upload Error:", error);
    notification.error({
      message: "Error",
      description: "Failed to upload bulk data.",
    });
  }
};

export const getBdms = async (obj) => {
  try {
    const { data } = await client.get(`/refund/getBdmName`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getAllRefund = async (obj) => {
  try {
    const { data } = await client.get(`/refund/getAllRefund`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const updateRefund = async (id, obj, pastRecord, userId) => {
  const dataToSend = {
    obj: obj,
    pastRecord: pastRecord,
    userId: userId,
  };
  try {
    const { data } = await client.put(`/refund/updateRefund/${id}`, dataToSend);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const deleteRefund = async (id, userId) => {
  try {
    const { data } = await client.delete(`/refund/deleteRefund/${id}`, {
      data: { userId },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
