import { Button, DatePicker } from "antd";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { getMenuLeadsCount, removeMenuLeads } from "../../api/menuLeads";

const RemoveMenuLeads = () => {
  const [menuDate, setMenuDate] = useState(null);
  const [menuLeadsCount, setMenuLeadsCount] = useState(0);
  const fetchMenuLeadsCount = async () => {
    if (menuDate) {
      const data = await getMenuLeadsCount(menuDate.format("YYYY-MM-DD"));
      if (data.success) {
        toast.success(data.message);
        setMenuLeadsCount(data.count);
      } else {
        toast.error(data.error);
        setMenuLeadsCount(0);
      }
    } else {
      toast.error("Please select a date");
      return;
    }
  };

  const deleteMenuLeads = async () => {
    if (menuDate) {
      const data = await removeMenuLeads(menuDate.format("YYYY-MM-DD"));
      if (data.success) {
        toast.success(data.message);
        setMenuDate(null);
      } else {
        toast.error(data.error);
      }
    } else {
      toast.error("Please select a date");
      return;
    }
  };

  return (
    <div className="flex justify-between items-center px-4">
      <div>
        <DatePicker onChange={(date) => setMenuDate(date)} />
        <Button type="primary" className="ml-4" onClick={fetchMenuLeadsCount}>
          Get Count
        </Button>
        <Button type="primary" className="ml-4" onClick={deleteMenuLeads}>
          Delete Menu Leads
        </Button>
      </div>
      <p className="font-semibold text-xl">Total Records : {menuLeadsCount}</p>
    </div>
  );
};

export default RemoveMenuLeads;
