import React, { useState, useEffect } from "react";
import { getAnalysis } from "../../../api/whatsapp";
import { Spin, DatePicker } from "antd"; // Ant Design component for loading spinner
import moment from "moment";

const Analysis = () => {
  const [analysis, setAnalysis] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [grandTotal, setGrandTotal] = useState(0); // Grand total state

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const fetchAnalysisData = async (date) => {
    setLoading(true);
    setError(null);
    try {
      const formattedDate = date ? date.format("YYYY-MM-DD") : null; // Format date
      const response = await getAnalysis(formattedDate); // Send request with date

      const data = response.data;
      const total = data.reduce(
        (acc, item) => acc + (item.Received_Amt || 0),
        0
      );
      setGrandTotal(total);

      setAnalysis(response.data);
    } catch (err) {
      setError("Failed to fetch analysis data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      fetchAnalysisData(selectedDate);
    }
  }, [selectedDate]);

  return (
    <div>
      <div className="flex justify-center text-center my-4 mb-5">
        <DatePicker onChange={handleDateChange} />
      </div>
      {loading ? (
        <Spin />
      ) : (
        <table
          className="table-auto w-11/12 mx-auto text-sm font-bold rounded-md"
          id="capture"
        >
          <thead className="text-black uppercase font-bold bg-[#FEFF00]">
            <tr>
              <th className="border border-black pb-2 font-bold text-center">
                Sr. No
              </th>
              <th className="border border-black pb-2 font-bold text-center">
                Company Name
              </th>
              <th className="border border-black pb-2 font-bold text-center">
                Phone Number
              </th>
              <th className="border border-black pb-2 border text-center">
                Template
              </th>
              <th className="border border-black pb-2 border text-center">
                Date
              </th>
              <th className="border border-black pb-2 font-bold text-center">
                Collections(₹)
              </th>
            </tr>
          </thead>
          <tbody>
            {analysis.map((item, index) => (
              <tr key={index}>
                <td className="border pb-2 border-black text-center">
                  {index + 1}
                </td>
                <td className="border pb-2 border-black text-center">
                  {item.COMPANY_NAME}
                </td>
                <td className="border pb-2 border-black text-center">
                  {item.number}
                </td>
                <td className="border pb-2 border-black text-center">
                  {item.template}
                </td>
                <td className="border pb-2 border-black text-center">
                  {moment(item.CREATED_AT).format("DD-MM-YYYY")}
                </td>
                <td className="border pb-2 border-black text-center">
                  {item.Received_Amt} ₹
                </td>
              </tr>
            ))}
            <tr className="bg-[#9BC0E7]">
              <td
                colSpan="2"
                className="border pb-2 border-black font-bold text-center uppercase"
              >
                Grand Total
              </td>

              <td className="border pb-2 border-black font-bold text-center">
                -
              </td>
              <td className="border pb-2 border-black font-bold text-center">
                -
              </td>
              <td className="border pb-2 border-black font-bold text-center">
                -
              </td>
              <td className="border pb-2 border-black font-bold text-center">
                {grandTotal} ₹
              </td>
              <td colSpan="4"></td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Analysis;
