import client from "./client";

export const getAllMapping = async () => {
  try {
    const { data } = await client.get(`/mapping/allMapping/`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const addMapping = async (type, name) => {
  try {
    const { data } = await client.post(`/mapping/addMapping`, { type, name });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const updateMapping = async (type, new_name, id) => {
  try {
    const { data } = await client.put(`/mapping/putMapping`, {
      type,
      new_name,
      id,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const deleteMapping = async (id) => {
  try {
    // Send the correct data format in the request body
    const { data } = await client.delete(`/mapping/deleteMapping`, {
      data: { id }, // Pass the body under `data` for DELETE requests
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
