import React, { useEffect, useState } from "react";
import { getLeadTrack } from "../../api/leadTracking";
import toast from "react-hot-toast";

// SVG Icons Components in minimal form for brevity
const PhoneIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
  </svg>
);

const UserIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="12" cy="8" r="4" />
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
  </svg>
);

const ClockIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="12" cy="12" r="10" />
    <path d="M12 6v6l4 2" />
  </svg>
);

const CalendarIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <rect x="3" y="4" width="18" height="18" rx="2" />
    <path d="M16 2v4M8 2v4M3 10h18" />
  </svg>
);

// Default data structure
const defaultData = {
  phoneNumber: "",
  sources: {
    airtel: [],
    tata: [],
    egniol: {
      leadsData: [],
      employeeDetails: [],
    },
  },
};

const LeadTracker = () => {
  const [activeTab, setActiveTab] = useState("airtel");
  const [searchNumber, setSearchNumber] = useState("");
  const [searchedData, setSearchedData] = useState(defaultData);
  const [loading, setLoading] = useState(false);

  // Simplified sample data
  //   const sampleData = {
  //     data: {
  //       phoneNumber: "7486038157",
  //       sources: {
  //         airtel: [
  //           {
  //             Caller_Number: "09898947701",
  //             Destination_Number: "07486038157",
  //             Date: "03/05/2024",
  //             Time: "15:45:16",
  //             duration: "13:20",
  //             recording_url:
  //               "https://openapi.airtel.in/gateway/airtel-xchange/ironman-data-transfer/download/recordingFile?token=gLTIM5uvaO6TqDKclWycfRjqVdJEVC0pMjXOrLSqNhLTwxT4gPSRD96hfvgic4xSpPc2hCGgJqYcSENjZSOM5Y7Hmls+8Y2hWF6DFD0VZMc=",
  //             agent_name: null,
  //           },
  //         ],
  //         tata: [
  //           {
  //             phone_number: "7486038157",
  //             campaign_name: null,
  //             agent_name: "HArshil",
  //             duration: 41,
  //             disposition_name: null,
  //             recording_url:
  //               "https://cloudphone.tatateleservices.com/file/recording?callId=1724995825.528622&type=rec&token=bjd6c0ZHR1lmVnpyMFRqcFpHSXlFUW8zeDJnd1FXMjFRM0x6MnMzbVJlKy9kRC9XdEVUUWZiYUgrcFlPYjE3Sjo6YWIxMjM0Y2Q1NnJ0eXl1dQ%3D%3D",
  //             lead_source: null,
  //             start_date: "2024-08-30T00:00:00.000Z",
  //           },
  //         ],
  //         egniol: {
  //           leadsData: [
  //             {
  //               leadId: 1148871,
  //               leadName: "M/s Rasawin Private Limited",
  //               contact: "7486038157",
  //               email: "rasawin2019@gmail.com",
  //               others: {
  //                 slab: "Slab: Rs. 1.5 Cr. to 5 Cr.",
  //                 type: "Private Limited Company",
  //               },
  //               source: "leads",
  //               slab: "Slab: Rs. 1.5 Cr. to 5 Cr.",
  //               type: "Private Limited Company",
  //             },
  //           ],
  //           employeeDetails: [
  //             {
  //               bdmId: 2,
  //               employeeID: "EGC0002",
  //               bdmName: "HARSHIL SARARIYA",
  //               emailId: "harshilsarariya@egniol.in",
  //               phonenumber: "9898329216",
  //               isActive: 1,
  //               floor: null,
  //               tlId: 37,
  //               campaignId: 464,
  //               loginActivity: 0,
  //               createdAt: "2024-07-13T06:30:18.000Z",
  //               role: "Admin",
  //               username: "Employee_73",
  //               wfh: 0,
  //               campaigns: ["467"],
  //               callCount: 3,
  //               callHistory: [
  //                 {
  //                   createAtDisposition: "2025-01-10T09:39:04.000Z",
  //                   dispoAt: "2025-01-10T09:39:04.000Z",
  //                   disposition: "Language Issue",
  //                   callDuration: 26,
  //                   recordingUrl:
  //                     "https://cloudcc.airtellive.com:8441/10.251.251.25/recordings/ho.egniol.airtel.com/archive/2025/Jan/10/20250110093817_7486038157.mp3",
  //                 },
  //                 {
  //                   createAtDisposition: "2025-01-10T09:39:04.000Z",
  //                   dispoAt: "2025-01-10T09:39:04.000Z",
  //                   disposition: "Language Issue",
  //                   callDuration: 26,
  //                   recordingUrl:
  //                     "https://cloudcc.airtellive.com:8441/10.251.251.25/recordings/ho.egniol.airtel.com/archive/2025/Jan/10/20250110093817_7486038157.mp3",
  //                 },
  //                 {
  //                   createAtDisposition: "2025-01-10T09:39:04.000Z",
  //                   dispoAt: "2025-01-10T09:39:04.000Z",
  //                   disposition: "Language Issue",
  //                   callDuration: 26,
  //                   recordingUrl:
  //                     "https://cloudcc.airtellive.com:8441/10.251.251.25/recordings/ho.egniol.airtel.com/archive/2025/Jan/10/20250110093817_7486038157.mp3",
  //                 },
  //               ],
  //             },
  //             {
  //               bdmId: 1,
  //               employeeID: "EGC0002",
  //               bdmName: "MIRAJ SOLIYA",
  //               emailId: "harshilsarariya@egniol.in",
  //               phonenumber: "9898329216",
  //               isActive: 1,
  //               floor: null,
  //               tlId: 37,
  //               campaignId: 464,
  //               loginActivity: 0,
  //               createdAt: "2024-07-13T06:30:18.000Z",
  //               role: "Admin",
  //               username: "Employee_73",
  //               wfh: 0,
  //               campaigns: ["467"],
  //               callCount: 3,
  //               callHistory: [
  //                 {
  //                   createAtDisposition: "2025-01-10T09:39:04.000Z",
  //                   dispoAt: "2025-01-10T09:39:04.000Z",
  //                   disposition: "Language Issue",
  //                   callDuration: 26,
  //                   recordingUrl:
  //                     "https://cloudcc.airtellive.com:8441/10.251.251.25/recordings/ho.egniol.airtel.com/archive/2025/Jan/10/20250110093817_7486038157.mp3",
  //                 },
  //                 {
  //                   createAtDisposition: "2025-01-10T09:39:04.000Z",
  //                   dispoAt: "2025-01-10T09:39:04.000Z",
  //                   disposition: "Language Issue",
  //                   callDuration: 26,
  //                   recordingUrl:
  //                     "https://cloudcc.airtellive.com:8441/10.251.251.25/recordings/ho.egniol.airtel.com/archive/2025/Jan/10/20250110093817_7486038157.mp3",
  //                 },
  //                 {
  //                   createAtDisposition: "2025-01-10T09:39:04.000Z",
  //                   dispoAt: "2025-01-10T09:39:04.000Z",
  //                   disposition: "Language Issue",
  //                   callDuration: 26,
  //                   recordingUrl:
  //                     "https://cloudcc.airtellive.com:8441/10.251.251.25/recordings/ho.egniol.airtel.com/archive/2025/Jan/10/20250110093817_7486038157.mp3",
  //                 },
  //               ],
  //             },
  //             {
  //               bdmId: 1,
  //               employeeID: "EGC0002",
  //               bdmName: "MIRAJ SOLIYA",
  //               emailId: "harshilsarariya@egniol.in",
  //               phonenumber: "9898329216",
  //               isActive: 1,
  //               floor: null,
  //               tlId: 37,
  //               campaignId: 464,
  //               loginActivity: 0,
  //               createdAt: "2024-07-13T06:30:18.000Z",
  //               role: "Admin",
  //               username: "Employee_73",
  //               wfh: 0,
  //               campaigns: ["467"],
  //               callCount: 3,
  //               callHistory: [
  //                 {
  //                   createAtDisposition: "2025-01-10T09:39:04.000Z",
  //                   dispoAt: "2025-01-10T09:39:04.000Z",
  //                   disposition: "Language Issue",
  //                   callDuration: 26,
  //                   recordingUrl:
  //                     "https://cloudcc.airtellive.com:8441/10.251.251.25/recordings/ho.egniol.airtel.com/archive/2025/Jan/10/20250110093817_7486038157.mp3",
  //                 },
  //                 {
  //                   createAtDisposition: "2025-01-10T09:39:04.000Z",
  //                   dispoAt: "2025-01-10T09:39:04.000Z",
  //                   disposition: "Language Issue",
  //                   callDuration: 26,
  //                   recordingUrl:
  //                     "https://cloudcc.airtellive.com:8441/10.251.251.25/recordings/ho.egniol.airtel.com/archive/2025/Jan/10/20250110093817_7486038157.mp3",
  //                 },
  //                 {
  //                   createAtDisposition: "2025-01-10T09:39:04.000Z",
  //                   dispoAt: "2025-01-10T09:39:04.000Z",
  //                   disposition: "Language Issue",
  //                   callDuration: 26,
  //                   recordingUrl:
  //                     "https://cloudcc.airtellive.com:8441/10.251.251.25/recordings/ho.egniol.airtel.com/archive/2025/Jan/10/20250110093817_7486038157.mp3",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       },
  //     },
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate phone number length
    if (searchNumber.trim().length !== 10) {
      toast.error("Please enter a 10-digit phone number.");
      return;
    }

    setLoading(true);
    try {
      // Simulate an API call
      const { data } = await getLeadTrack(searchNumber); // Replace with actual API call
      if (data) {
        setSearchedData(data.sources); // Update state with fetched data
      }
    } catch (error) {
      console.error("Error during search:", error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const formatDuration = (duration) => {
    if (typeof duration === "string") return duration;
    return `${Math.floor(duration / 60)}:${(duration % 60)
      .toString()
      .padStart(2, "0")}`;
  };

  const formatDate = (dateString) => {
    try {
      return new Date(dateString).toLocaleDateString();
    } catch (e) {
      return dateString;
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="w-full max-w-6xl mx-auto p-4 space-y-4">
      <div className="bg-white rounded-lg shadow-md p-6">
        <form onSubmit={handleSubmit} className="flex gap-4">
          <div className="flex-1">
            <input
              type="text"
              value={searchNumber}
              onChange={(e) => setSearchNumber(e.target.value)}
              placeholder="Enter phone number to search..."
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className={`px-6 py-2 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 ${
              loading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
            }`}
          >
            {loading ? (
              <svg
                className="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              "Search"
            )}
          </button>
        </form>
      </div>

      <div className="w-full">
        <div className="border-b border-gray-200">
          <nav className="flex justify-center">
            <button
              onClick={() => setActiveTab("airtel")}
              className={`px-4 py-2 font-medium ${
                activeTab === "airtel"
                  ? "border-b-2 border-blue-500 text-blue-600"
                  : "text-gray-500 hover:text-gray-700"
              }`}
            >
              Airtel Calls
            </button>
            <button
              onClick={() => setActiveTab("tata")}
              className={`px-4 py-2 font-medium ${
                activeTab === "tata"
                  ? "border-b-2 border-blue-500 text-blue-600"
                  : "text-gray-500 hover:text-gray-700"
              }`}
            >
              Tata Calls
            </button>
            <button
              onClick={() => setActiveTab("egniol")}
              className={`px-4 py-2 font-medium ${
                activeTab === "egniol"
                  ? "border-b-2 border-blue-500 text-blue-600"
                  : "text-gray-500 hover:text-gray-700"
              }`}
            >
              Egniol Data
            </button>
          </nav>
        </div>

        <div className="mt-6">
          {activeTab === "airtel" && (
            <div className="grid gap-4 md:grid-cols-2">
              {searchedData.airtel?.map((call, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-md overflow-hidden"
                >
                  <div className="bg-blue-50 px-4 py-3">
                    <div className="flex items-center gap-2 font-semibold">
                      <span className="w-5 h-5">
                        <PhoneIcon />
                      </span>
                      Call Record {index + 1}
                    </div>
                  </div>
                  <div className="p-4 space-y-3">
                    <div className="grid grid-cols-2 gap-2">
                      <div className="flex items-center gap-2">
                        <span className="w-4 h-4 text-gray-500">
                          <PhoneIcon />
                        </span>
                        <span>From: {call.Caller_Number}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="w-4 h-4 text-gray-500">
                          <PhoneIcon />
                        </span>
                        <span>To: {call.Destination_Number}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="w-4 h-4 text-gray-500">
                          <ClockIcon />
                        </span>
                        <span>Duration: {call.duration}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="w-4 h-4 text-gray-500">
                          <CalendarIcon />
                        </span>
                        <span>
                          {call.Date} {call.Time}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="w-4 h-4 text-gray-500">
                          <UserIcon />
                        </span>
                        <span>{call.agent_name || "No Agent"}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        {call.recording_url && (
                          <audio controls className="w-full mt-2">
                            <source
                              src={call.recording_url}
                              type="audio/mpeg"
                            />
                          </audio>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {activeTab === "tata" && (
            <div className="grid gap-4 md:grid-cols-2">
              {searchedData.tata?.map((call, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-md overflow-hidden"
                >
                  <div className="bg-purple-50 px-4 py-3">
                    <div className="flex items-center gap-2 font-semibold">
                      <span className="w-5 h-5">
                        <PhoneIcon />
                      </span>
                      Call Record {index + 1}
                    </div>
                  </div>
                  <div className="p-4 space-y-3">
                    <div className="grid grid-cols-2 gap-2">
                      <div className="flex items-center gap-2">
                        <span className="w-4 h-4 text-gray-500">
                          <UserIcon />
                        </span>
                        <span>{call.agent_name}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="w-4 h-4 text-gray-500">
                          <ClockIcon />
                        </span>
                        <span>{formatDuration(call.duration)}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="w-4 h-4 text-gray-500">
                          <CalendarIcon />
                        </span>
                        <span>{formatDate(call.start_date)}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="w-4 h-4 text-gray-500">
                          <CalendarIcon />
                        </span>
                        <span>{call.disposition_name || "No Disposition"}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="w-4 h-4 text-gray-500">
                          <CalendarIcon />
                        </span>
                        <span>{call.campaign_name || "No Campaign"}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        {call.recording_url && (
                          <audio controls className="w-full mt-2">
                            <source
                              src={call.recording_url}
                              type="audio/mpeg"
                            />
                          </audio>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {activeTab === "egniol" && (
            <div className="space-y-6">
              <div className="bg-white rounded-lg shadow-md overflow-hidden">
                <div className="px-4 py-3 border-b border-gray-200">
                  <h2 className="text-lg font-semibold">Lead Information</h2>
                </div>
                <div className="p-4">
                  <div className="grid gap-4 md:grid-cols-2">
                    {searchedData.egniol?.leadsData?.map((lead, index) => (
                      <div key={index} className="p-4 border rounded-lg">
                        <h3 className="font-semibold mb-2">{lead.leadName}</h3>
                        <div className="space-y-2 text-sm">
                          <p>Lead ID: {lead.leadId}</p>
                          <p>Email: {lead.email}</p>
                          <p>Source: {lead.source}</p>
                          <p>Slab: {lead.slab}</p>
                          {lead.type && <p>Type: {lead.type}</p>}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-lg shadow-md overflow-hidden">
                <div className="px-4 py-3 border-b border-gray-200">
                  <h2 className="text-lg font-semibold">Employee Details</h2>
                </div>
                <div className="p-4">
                  <div className="grid gap-4 md:grid-cols-2">
                    {searchedData.egniol?.employeeDetails?.map((emp, index) => (
                      <div
                        key={index}
                        className="p-4 border rounded-lg space-y-4"
                      >
                        <div>
                          <h3 className="font-semibold mb-2">{emp.bdmName}</h3>
                          <div className="space-y-2 text-sm">
                            <p>Employee ID: {emp.employeeID}</p>
                            <p>Email: {emp.emailId}</p>
                            <p>Phone: {emp.phonenumber}</p>
                            <p>Role: {emp.role}</p>
                            <p>
                              Status: {emp.isActive ? "Active" : "Inactive"}
                            </p>
                            <p>Work From Home: {emp.wfh ? "Yes" : "No"}</p>
                            <p>Total Call Count: {emp.callCount}</p>
                          </div>
                        </div>

                        {emp.callHistory && emp.callHistory.length > 0 && (
                          <div>
                            <h4 className="font-semibold mb-2 text-blue-600">
                              Call History
                            </h4>
                            {emp.callHistory.map((call, callIndex) => (
                              <div
                                key={callIndex}
                                className="bg-gray-50 p-3 rounded border space-y-2"
                              >
                                <div className="flex items-center gap-2">
                                  <span className="w-4 h-4 text-gray-500">
                                    <ClockIcon />
                                  </span>
                                  <span>
                                    Duration: {call.callDuration} seconds
                                  </span>
                                </div>
                                <div className="flex items-center gap-2">
                                  <span className="w-4 h-4 text-gray-500">
                                    <CalendarIcon />
                                  </span>
                                  <span>
                                    Date:{" "}
                                    {new Date(call.dispoAt).toLocaleString()}
                                  </span>
                                </div>
                                <p className="text-sm">
                                  Disposition: {call.disposition}
                                </p>
                                {call.recordingUrl && (
                                  <audio controls className="w-full mt-2">
                                    <source
                                      src={call.recordingUrl}
                                      type="audio/mpeg"
                                    />
                                  </audio>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeadTracker;
