import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const role = localStorage.getItem("role");
  const email = localStorage.getItem("email");
  const handleLogout = async () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    navigate("/login");
  };

  return (
    <header className="bg-zinc-800 p-4 flex justify-between items-center w-full">
      <div className="text-white text-xl font-bold">
        <img src={logo} alt="logo" width={100} height={100} />
      </div>

      <div className="flex justify-center items-center">
        {/* <div className="mr-4">
          <Link
            to="/egniol_updates"
            className={`text-center font-semibold text-xl  ${
              location.pathname === "/egniol_updates"
                ? "border-b-2 border-orange-500 text-orange-500"
                : "hover:border-b hover:border-orange-500 text-white"
            } `}
          >
            Egniol Updates
          </Link>
        </div> */}
        {role === "admin" && localStorage.getItem("authToken") && (
          <>
            {/* <div className="mr-4">
              <Link
                to="/insights"
                className={`text-center font-semibold text-xl  ${
                  location.pathname === "/insights"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white"
                } `}
              >
                Insights
              </Link>
            </div> */}
            {/* <div className="mr-4">
              <Link
                to="/pickup"
                className={`text-center font-semibold text-xl  ${location.pathname === "/pickup"
                  ? "border-b-2 border-orange-500 text-orange-500"
                  : "hover:border-b hover:border-orange-500 text-white"
                  } `}
              >
                Leads Pickup
              </Link>
            </div> */}
            <div className="mr-4">
              <Link
                to="/refund-form"
                className={`text-center font-semibold text-xl  ${
                  location.pathname === "/refund-form"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white"
                } `}
              >
                Refund
              </Link>
            </div>
            <div className="mr-4">
              <Link
                to="/menuLeads"
                className={`text-center font-semibold text-xl  ${
                  location.pathname === "/menuLeads"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white"
                } `}
              >
                Menu Leads
              </Link>
            </div>

            <div className="mr-4">
              <Link
                to="/team"
                className={`text-center font-semibold text-xl  ${
                  location.pathname === "/team"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white"
                } `}
              >
                Team
              </Link>
            </div>
            <div className="mr-4">
              <Link
                to="/airtel"
                className={`text-center font-semibold text-xl  ${
                  location.pathname === "/airtel"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white"
                } `}
              >
                Airtel
              </Link>
            </div>
            <div className="mr-4">
              <Link
                to="/ivr"
                className={`text-center font-semibold text-xl ${
                  location.pathname === "/ivr"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white "
                } `}
              >
                IVR
              </Link>
            </div>
            <div className="mr-4">
              <Link
                to="/lead-tracker"
                className={`text-center font-semibold text-xl ${
                  location.pathname === "/lead-tracker"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white "
                } `}
              >
                Lead Tracker
              </Link>
            </div>
            <div className="mr-4">
              <Link
                to="/incoming"
                className={`text-center font-semibold text-xl ${
                  location.pathname === "/incoming"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white "
                } `}
              >
                Incoming
              </Link>
            </div>

            {/* <div className="mr-4">
              <Link
                to="/callhippo"
                className={`text-center font-semibold text-xl  ${location.pathname === "/callhippo"
                  ? "border-b-2 border-orange-500 text-orange-500"
                  : "hover:border-b hover:border-orange-500 text-white"
                  } `}
              >
                Call Hippo
              </Link>
            </div> */}
            {/* <div className="mr-4">
              <Link
                to="/openPhone"
                className={`text-center font-semibold text-xl  ${
                  location.pathname === "/openPhone"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white"
                } `}
              >
                Canada
              </Link>
            </div> */}
            {/* <div className="mr-4">
              <Link
                to="/dialer-report"
                className={`text-center font-semibold text-xl  ${
                  location.pathname === "/dialer-report"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white"
                } `}
              >
                Dialer
              </Link>
            </div> */}
            <div className="mr-4">
              <Link
                to="/reports"
                className={`text-center font-semibold text-xl  ${
                  location.pathname === "/reports"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white"
                } `}
              >
                Generate_Report
              </Link>
            </div>
            {/* {[
              "harshilsarariya@egniol.in",
              "lalit@egniol.co.in",
              "mirajsoliya@egniol.in",
              "neelsoni@egniol.in",
            ].includes(email) && (
              <div className="mr-4">
                <Link
                  to="/Whatsapp"
                  className={`text-center font-semibold text-xl  ${
                    location.pathname === "/Whatsapp"
                      ? "border-b-2 border-orange-500 text-orange-500"
                      : "hover:border-b hover:border-orange-500 text-white"
                  } `}
                >
                  Whatsapp
                </Link>
              </div>
            )} */}
            <div className="mr-4">
              <Link
                to="/complain_form"
                className={`text-center font-semibold text-xl  ${
                  location.pathname === "/complain_form"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white"
                } `}
              >
                ComplainForm
              </Link>
            </div>

            <div className="mr-4">
              <button
                className="hover:border-b font-semibold text-xl text-white border-gray-300 hover:border-orange-500 transtion duration-300 ease-in-out hover:text-orange-500 py-1"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
            {/* <div className="mr-4">
              <Link
                to="/export_data"
                className={`text-center font-semibold text-xl  ${
                  location.pathname === "/export_data"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white"
                } `}
              >
                Export_Data
              </Link>
            </div> */}
          </>
        )}
        {/* Accounts Role  */}

        {role === "accounts" && localStorage.getItem("authToken") && (
          <>
            <div className="mr-4">
              <Link
                to="/refund-form"
                className={`text-center font-semibold text-xl  ${
                  location.pathname === "/refund-form"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white"
                } `}
              >
                Refund
              </Link>
            </div>
            <div className="mr-4">
              <button
                className="hover:border-b font-semibold text-xl text-white border-gray-300 hover:border-orange-500 transtion duration-300 ease-in-out hover:text-orange-500 py-1"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </>
        )}

        {/* sales */}
        {role === "sales" && localStorage.getItem("authToken") && (
          <>
            <div className="mr-4">
              <Link
                to="/dispo"
                className={`text-center font-semibold text-xl ${
                  location.pathname === "/dispo"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white "
                } `}
              >
                Perfomance Tracker
              </Link>
            </div>
            {/*  <div className="mr-4">
              <Link
                to="/incomeTax"
                className={`text-center font-semibold text-xl ${
                  location.pathname === "/incomeTax"
                    ? "border-b-2 border-orange-500 text-orange-500"
                    : "hover:border-b hover:border-orange-500 text-white "
                } `}
              >
                Income Tax
              </Link>
            </div> */}

            <div className="mr-4">
              <button
                className="hover:border-b font-semibold text-xl text-white border-gray-300 hover:border-orange-500 transtion duration-300 ease-in-out hover:text-orange-500 py-1"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </>
        )}
      </div>
      {/* // )} */}
    </header>
  );
};

export default Header;
