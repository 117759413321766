import client from "./client";

export const getDialerReport = async () => {
  try {
    const { data } = await client.get(`/dialer/dialer_report`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getAgentsReport = async (obj) => {
  try {
    const { data } = await client.post(`/dialer/agents-details`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getTalkTimeCdr = async (obj) => {
  try {
    const { data } = await client.post(`/dialer/agent_taltime_cdr`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
