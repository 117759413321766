import client from "./client";

export const getSearchNumber = async (time, agentName, number) => {
  try {
    const result = { time, agentName, number };
    const { data } = await client.post(`/searchNumber/getSearchNumber`, result);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
